// =============================================
//	OVERRIDE BOOTSTRAP & PLUGIN VARIABLES
//	Notice that there is a !default directive at the end of each declaration line.
//
//	This directive means: current variable will be defined and set value ONLY if it doesn’t be defined or have a value already.
//	In brief: "If not set value yet, set it, else, keep old value!".
//
//	So if we assign our own vaule to those variables BEFORE importing Bootstrap, we can override them.
// =============================================

// =============================================
// DEFAULTS
// =============================================

// Colors
$white:               		  #fff;
$black:               		  #000;
$gray-dark:                 #373a3c;
$gray:                      #55595c;
$gray-light:                #818a91;
$gray-lighter:              #eceeef;
$gray-lightest:             #f7f7f9;

$dark: 										  #333030;
$ocean: 									  #2b90d9;
$grass: 										#3ac569;
$snow: 											#FFFFFF;
$salmon: 										#ff7473;
$sun: 											#feee7d;
$alge:										  #79a8a9;
$flower: 										#353866;
$orange: 										#eaa900;
$green: 										#58ac0f;
$blue: 											#043e85;
$red: 											#e10505;
$lightblue: 								#53a4dc;
$olive: 										#a8a800;
$brown: 										#876e44;
$overlay-rgb:               rgba(46,97,158,.85); // box hover

$brand-primary:             $blue;
$brand-secondary:           #83a552;
$brand-success:             #5cb85c;
$brand-info:                #5bc0de;
$brand-warning:             #f0ad4e;
$brand-danger:              #d9534f;

$txt-overlay-rgb:           rgba(255,255,255,.85);

// Border
$border-color:								#dfdfdf;
$border-radius:								3px;

// Shadow
$box-shadow:									0 26px 40px -20px rgba(0,0,0,0.2);
$txt-shadow-white:						0 1px 0px rgba(255,255,255,.8);
$txt-shadow-black:						0 1px 3px rgba(0,0,0,.8);

// Transitions
$transition-fast:							250ms;
$transition-slow:							500ms;

// Grid
$grid-gutter-width:        		1.875rem; // 30px
$grid-gutter-half-width:     	$grid-gutter-width/2; // 15px

// DateTimePicker

$border-radius-base:						$border-radius;
$screen-sm-min:					$grid-gutter-width;
$screen-md-min:					$grid-gutter-width;
$screen-lg-min:					$grid-gutter-width;

// =============================================
// SPACING
//
// Control the default styling of most Bootstrap elements by modifying these
// variables. Mostly focused on spacing.
// You can add more entries to the $spacers map, should you need more variation.
// =============================================


$spacer:	2rem;
$spacer-x: $spacer;
$spacer-y: $spacer;
$spacers: (
  0: (
    x: 0,
    y: 0
  ),
  1: (
    x: ($spacer-x * .25),
    y: ($spacer-y * .25)
  ),
  2: (
    x: ($spacer-x * .5),
    y: ($spacer-y * .5)
  ),
  3: (
    x: ($spacer-x * .75),
    y: ($spacer-y * .75)
  ),
  4: (
    x: $spacer-x,
    y: $spacer-y
  ),
  5: (
    x: ($spacer-x * 1.25),
    y: ($spacer-y * 1.25)
  ),
  6: (
    x: ($spacer-x * 1.5),
    y: ($spacer-y * 1.5)
  ),
  7: (
    x: ($spacer-x * 1.75),
    y: ($spacer-y * 1.75)
  ),
  8: (
    x: ($spacer-x * 2),
    y: ($spacer-y * 2)
  ),
  9: (
    x: ($spacer-x * 2.5),
    y: ($spacer-y * 2.5)
  ),
  10: (
    x: ($spacer-x * 3),
    y: ($spacer-y * 3)
  )
);



// =============================================
// LIGHTGALLERY
// =============================================

// font paths
$lg-path-fonts: 						'/dist/fonts';
$lg-path-images: 						'/dist/img';

// =============================================
// BOOTSTRAP GLOBALS (added some new variables)
// =============================================

// Options
$enable-flex:               false;
$enable-rounded:            false;
$enable-transitions:        true;

// Body
$body-bg:                    #fff;
$body-color:                 #555;

// Links
$link-color:                 $brand-secondary;
$link-hover-color:           darken($link-color, 5%);
$link-active-color:          #000;
$link-decoration:            none;
$link-hover-decoration:      none;
$link-active-decoration:     none;

// Typography
$font-family-sans-serif:     'Open Sans', sans-serif;
$font-family-serif:          'Open Sans', serif;
$font-family-monospace:      Menlo, Monaco, Consolas, "Courier New", monospace;
$font-family-base:           $font-family-sans-serif;

$headings-font-family:       $font-family-serif;
$headings-font-weight:       300;
$headings-line-height:       1.1;
$headings-color:             $body-color;

$font-size-root:             10px;

$font-size-base:             1.8rem;
$font-size-xxxl:             3.4rem;
$font-size-xxl:              3rem;
$font-size-xl:               2.6rem;
$font-size-lg:               2rem;
$font-size-md:               1.8rem;
$font-size-sm:               1.6rem;
$font-size-xs:               1.4rem;
$font-size-xxs:              1.2rem;
$font-size-xxxs:             1rem;

// Line Height
$line-height:                 1.75;
$line-height-xl:              1.3;
$line-height-lg:              1.4;
$line-height-md:              1.5;
$line-height-sm:              1.6;
$line-height-xs:              1.7;
$line-height-xxs:             1.8;

// Font Weight
$display1-weight:             300;
$display2-weight:             400;
$display3-weight:             600;
$display4-weight:             700;
$display5-weight:             800;

// Forms

$input-padding-x:                2rem;
$input-padding-y:                .75rem;

// Buttons

$btn-padding-x:                  2rem;
$btn-padding-y:                  .75rem;
$btn-line-height:                1.25 !default;
$btn-font-weight:                normal !default;
$btn-box-shadow:                 inset 0 1px 0 rgba(255,255,255,.15), 0 1px 1px rgba(0,0,0,.075) !default;
$btn-active-box-shadow:          inset 0 3px 5px rgba(0,0,0,.125) !default;

$btn-primary-color:              #fff !default;
$btn-primary-bg:                 $brand-secondary;
$btn-primary-border:             $btn-primary-bg !default;

$btn-secondary-color:            $gray-dark !default;
$btn-secondary-bg:               #fff !default;
$btn-secondary-border:           #ccc !default;

$btn-info-color:                 #fff !default;
$btn-info-bg:                    $brand-info !default;
$btn-info-border:                $btn-info-bg !default;

$btn-success-color:              #fff !default;
$btn-success-bg:                 $brand-success !default;
$btn-success-border:             $btn-success-bg !default;

$btn-warning-color:              #fff !default;
$btn-warning-bg:                 $brand-warning !default;
$btn-warning-border:             $btn-warning-bg !default;

$btn-danger-color:               #fff !default;
$btn-danger-bg:                  $brand-danger !default;
$btn-danger-border:              $btn-danger-bg !default;

$btn-link-disabled-color:        $gray-light !default;

$btn-padding-x-sm:               .5rem !default;
$btn-padding-y-sm:               .25rem !default;

$btn-padding-x-lg:               1.5rem !default;
$btn-padding-y-lg:               .75rem !default;

$btn-block-spacing-y:            .5rem !default;
$btn-toolbar-margin:             .5rem !default;

// =============================================
// Main Nav
// =============================================

// Typography
$font-size-main-nav:				2.2rem;

// =============================================
// MOBILE NAV (sidr)
// =============================================

// Sizes
$sidr-button-width:						5rem; // .menu-show, .menu-close
$sidr-button-height:					5rem; // .menu-show, .menu-close
$sidebar-width: 					  	100%;
$sidebar-width-neg: 			 	  -100%;

// Transitions
$sidr-transition:							.4s; // slide-in, fade-in, max-height transitions
$sidr-transition-delay:				.2s; // should be half of transition

// Backgrounds
$sidr-background: 					 	#3f3f3f;
$sidr-background-lvl-1: 			#3f3f3f;
$sidr-background-lvl-2:				#333;
$sidr-background-lvl-3:				#222;

// Links
$sidr-link-color:							#fff;
$sidr-link-lvl-2: 						#999;
$sidr-link-lvl-3: 						#999;

// Border
$sidr-border-color-lvl-1:			#333;
$sidr-border-color-lvl-2:			#222;
$sidr-border-color-lvl-3:			#000;

// =============================================
// TOPBAR
// =============================================

$topbar-height:							  4rem;

// =============================================
// HEADER
// =============================================

$header-height:							  8rem; // Phones, Tablets
$header-lg-height:						10rem; // Dekstops

// =============================================
// ACTIONS
// =============================================

$actions-li-width: 						5rem;
$actions-li-height: 					5rem;
$actions-li-color: 						#777;
$actions-li-color-hover: 			#fff;

$search-input-bg-color: 			#fff;
