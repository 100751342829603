// =============================================
// VARIABLES
// =============================================

		@import "../scss/_variables.scss";

// =============================================
// FRAMEWORKS
// =============================================

    // Google Font
    @import "https://fonts.googleapis.com/css?family=Open+Sans:400,300,600,700";

		//	Bootstrap
		@import "bower_components/bootstrap/scss/bootstrap.scss";

// =============================================
// PLUGINS
// =============================================

		// Swiper - Modern Touch Slider
		// Source: http://idangero.us/swiper/#.Vzxt0pGLSUk
		@import "/bower_components/Swiper/dist/css/swiper.min.css";
		// Lightgallery - A customizable, modular, responsive, lightbox gallery
		// Source: https://github.com/sachinchoolur/lightGallery
		@import "bower_components/lightgallery/src/sass/lightgallery.scss";
		// Malihu custom scrollbar plugin
		// Source: https://github.com/malihu/malihu-custom-scrollbar-plugin
		@import "/bower_components/malihu-custom-scrollbar-plugin/jquery.mCustomScrollbar.css";
		// Bootstrap Datetimepicker
		@import "bower_components/eonasdan-bootstrap-datetimepicker/src/sass/bootstrap-datetimepicker-build.scss";				

// =============================================
// DEFAULT
// =============================================

		//	advanced custom mixins
		@import "../scss/_custom-mixins.scss";

		//	frequently used elements, such as quotes, lists etc.
		@import "../scss/_components.scss";

		//	Default Styles (Main File)
		@import "../scss/_styles.scss";

		//	Responsive Styles
		@import "../scss/_responsive.scss";


