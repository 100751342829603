// =============================================
// WEBEDITION
// ============================================

.wetextinput {
	width: 100%!important;
}

.weDialogBody {
	max-width: 60vw!important;
}

// =============================================
// DEFAULTS
// =============================================

html {
	font-size: 9px;
	box-sizing: border-box;
	*behavior: url(/dist/css/assets/boxsizing.htc);
}

.pos-relative {
	position: relative;
}

// Links
a {
	transition: all $transition-fast;

	&:hover,
	&:focus {
		color: $link-hover-color;
		text-decoration: $link-hover-decoration;
	}

	&:active {
		color: $link-active-color;
		text-decoration: $link-active-decoration;
	}
}

.main-content {
	margin: 3rem 0;
}

// =============================================
// CUSTOM ALERTS
// =============================================

.alert-wrapper {
  @include alert-variant(#99a1a9, #7f858c, #5a5a5a);
}

.alert-wrapper-opt {
  @include alert-variant(#c2cbd4, #979ea5, #5a5a5a);
  border: none;
  border-bottom: 1px dashed;
}

.alert-add {
  @include alert-variant(#fcf8e3, darken(#fcf8e3, 7%), #8a6d3b);
}

.alert-modul {
  @include alert-variant(#d9edf7, darken(#d9edf7, 7%), #31708f);
}

.alert-modul-opt {
  @include alert-variant(darken(#d9edf7, 7%), darken(#d9edf7, 30%), darken(#d9edf7, 60%));
  border: none;
  border-bottom: 1px dashed;
}


// =============================================
// FLEXBOX POLYFILLS
// Help: https://kyusuf.com/post/almost-complete-guide-to-flexbox-without-flexbox
// =============================================

.display-table {
	display: table;
}

.display-table-cell {
	display: table-cell;
}

.shrink {
	width: 1px;
}

.grow {
	width: 100%;
}

.bottom {
	vertical-align: bottom;
}

.center {
	vertical-align: middle;
}

// =============================================
// FLEXBOX
// =============================================

.display-flex {
	display: flex;
}

.flex-grow-1 {
	flex-grow: 1; // take remaining space
}

.flex-1 {
	flex: 1;
}

.flex-shrink-0 {
	flex-shrink: 0;
}

.justify-content-center {
	justify-content: center;
}

// ALIGNMENT

// vertical
.valign-center {
	display: flex;
	align-items: center;
}

.valign-top {
	display: flex;
	align-items: flex-start;
}

.valign-bottom {
	display: flex;
	align-items: flex-end;
}

// horizontal
.halign-center {
	//width: 100%;
	display: flex;
	justify-content: center;
}

.halign-left {
	width: 100%;
	display: flex;
	justify-content: flex-start;
}

.halign-right {
	width: 100%;
	display: flex;
	justify-content: flex-end;
}

// =============================================
// MARGIN & PADDING & SIZES
// =============================================

.m-l-50vw {
	margin-right: 50vw;
}

.m-r-50vw {
	margin-left: 50vw;
}

.mr-1 {
	margin-right: 1rem;
}

// =============================================
// CONTAINER
// =============================================

.wrapper-elements {
	position: relative;
	overflow: hidden;

	.add-bg-img {
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    z-index: -1;
 	}

	> .container-fluid {
		padding-left: 0;
		padding-right: 0;
	}
}

.wrapper-center {
	display: flex;
	justify-content: center;
	align-items: center;
}

.height-25vh {
	height: 25vh;
}

.height-50vh {
	height: 50vh;
}

.height-75vh {
	height: 75vh;
}

.height-100vh {
	height: 100vh;
}


// =============================================
// COLORS
// =============================================

.white {
  color: $white;
}
.bg-white {
  background-color: $white;
}
.black {
  color: $black;
}
.bg-black {
  background-color: $black;
}

.gray {
  color: $gray;
}
.bg-gray {
  background-color: $gray;
}

.gray-light {
  color: $gray-light;
}
.bg-gray-light {
  background-color: $gray-light;
}

.gray-lighter {
  color: $gray-lighter;
}
.bg-gray-lighter {
  background-color: $gray-lighter;
}

.dark {
  color: #373a3c;
}
.bg-dark {
  background-color: #373a3c;
}

.white {
  color: #fff;
}
.bg-white {
  background-color: #fff;
}

.red {
  color: $red;
}
.bg-red {
  background-color: $red;
}

.yellow {
  color: #f1c40f;
}
.bg-yellow {
  background-color: #f1c40f;
}

.blue {
  color: $blue;
}
.bg-blue {
  background-color: $blue;
}

.blue-light {
  color: $lightblue;
}
.bg-blue-light {
  background-color: $lightblue;
}

.orange {
  color: $orange;
}
.bg-orange {
  background-color: $orange;
}

.green {
  color: $green;
}
.bg-green {
  background-color: $green;
}

.oliv {
  color: $olive;
}
.bg-oliv {
  background-color: $olive;
}


// =============================================
// OVERLAY
// =============================================

.overlay {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 500;
  background-color: #fff;
  opacity: 0;
  visibility: hidden;
	transition: all .3s ease; // transition speed should be equal to the mobile nav slide-in

	&.open {
		opacity: .9;
	  visibility: visible;
  	transition: all .3s ease; // transition speed should be equal to the mobile nav slide-in
	}
}

// =============================================
// HEADER
// =============================================

// Menu default

.menu-fixed {
 	margin-top: $header-height;
}

.headroom {
  //position: fixed;
  z-index: 1100;
  width: 100%;
  top: 0;
  transition: all .25s ease-in-out;

  z-index: 100;

  &--pinned {
      transform: translateY(0%);
  }
  &--unpinned {
      transform: translateY(-100%);
  }
}

// Menu overlay

.menu-transparent {
	.headroom {
    box-shadow: 0 5px 15px -5px rgba(0,0,0,.2);

    &.headroom--unpinned {
    	.wrap-topbar,
    	.wrap-header {
    		opacity: 0;
    		transition: all 500ms;
    	}
    }

    &.headroom--pinned {
    	.wrap-topbar,
    	.wrap-header {
    		opacity: 1;
    		transition: all 500ms;
    	}
    }

		&:not(.headroom--not-top) {
			// Transparent style
			background: rgba(255,255,255,.25);
			transition: all $transition-fast;

			.wrap-header,
			.wrap-topbar {
				background: transparent;
				border: none;
			}

			.wrap-topbar {
				ul li {
					color: rgba(255,255,255,.75);

					a {
						color: rgba(255,255,255,.75);

		  			&:hover,
						&:active {
							color: rgba(255,255,255,1);
		  			}
		  		}
				}
			}

			.navbar-main .lvl-1>li>a {
				color: #fff;
			}

			.actions-inner .actions .square {
				background: rgba(255,255,255,.1);

				.ico {
					color: rgba(255,255,255,.5);
				}
			}
		}
	}
}

// Menu and topbar

.wrap-topbar {
	background: #fff;
}

.wrap-header {
	border-bottom: 1px solid $border-color;
	background: #fff;
	padding: 2rem 0;
	margin-bottom: 3rem;
	width: 100%;
  display: table;
  vertical-align: middle;

	.header {
		position: relative;
		display: table; // flex poly
		margin: 0 auto;
		border: none;

		.wrap-logo {
			display: table-cell;
			width: 100%;
			vertical-align: middle;

			.logo {
				display: block;
				width: 13rem;
			}
		}

		.nav-main {
			display: table-cell;
			text-align: right;
		}
	}
}

// =============================================
// HEADBAR
// =============================================

.headbar {
	display: table-cell;
	vertical-align: middle;

	.headbar-list {
		width: 20rem;

		ul {
			list-style: none;
			margin: 0;
			padding: 0;
			text-align: right;
			@include clearfix;

			li {
				display: inline-block;
				margin-left: 1rem;

				&:first-child {
					margin-left: 0;
				}

				a {
					position: relative;
					display: block;
			    background: #f0f0f0;
			    border: 1px solid #ddd;
			    border-radius: $border-radius;
			    color: $body-color;
			    font-size: 1.4rem;
			    font-weight: 600;
			    transition: none;

	    		&:hover,
	    		&:focus {
	    			background: $link-hover-color;
		    		border-color: $brand-secondary;
		    		color: #fff;

	    			.ico {
			    		color: #fff;

			    		&.ico-append {
			    			background: darken($link-hover-color, 3%);
				    		border-color: darken($link-hover-color, 3%);
			    		}
	    			}
	    		}

	    		&:active {
	    			background: darken($link-hover-color, 10%);
	    			border-color: darken($link-hover-color, 10%);

	    			.ico,
	    			.ico.ico-append {
	    				color: #fff;
	    				background: inherit;
	    				border-color: inherit;
	    			}
	    		}
			  }

				.ico {
					display: inline-block;
					width: 5rem;
					height: 5rem;
					line-height: 5rem;
					text-align: center;
					color: #777;
					font-size: 2rem;

					&.ico-append {
				    border-right: 1px solid #dfdfdf;
				    background: #e8e8e8;
					}
				}

				// AddOns's

				.cart-price {
					padding: 0 2rem 0 1.5rem;
				}

		    .badge {
			    position: absolute;
			    top: -1rem;
			    background: #555;
			    height: 2.5rem;
			    width: 2.5rem;
			    line-height: 1.7;
			    color: #fff;
			    border-radius: 100%;
			    right: -1rem;
			    font-size: $font-size-xs;
			    text-align: center;
			    text-shadow: 1px 1px 1px rgba(0,0,0,.25);
			    font-weight: 600;
		    }

		    // Custom

		    .menu-show {
	    		background: $brand-secondary;
	    		border-color: $brand-secondary;

		    	.ico {
		    		color: #fff;
		    	}
		    }
			}
		}
	}
}

// =============================================
// ACTIONS
// =============================================

.wrap-actions {
	display: table-cell;
	vertical-align: middle;
	padding-left: 1rem;
	padding-right: 2rem;
	min-width: 17rem;
	text-align: right;

	.actions-inner {
		display: block;

		ul {
			display: block;
			list-style: none;
			margin: 0;
			padding: 0;
			font-size: 0;
		}
	}
}

.actions {
	position: relative;
	list-style: none;
	width: 100%;
  margin: 0 0 0 1.5rem;
  padding: 0;


  .align-item {
  	width: 100%;
  	height: 100%;
  }

	.square {
		display: inline-block;
    min-width: $actions-li-width;
    height: $actions-li-height;
    text-align: center;
    transition: all $transition-fast;
		position: relative;
    margin-left: 2rem;
    border-radius: $border-radius;
    font-size: 2rem;
		background: #f0f0f0;
		border: 1px solid #ddd;

		&.square-extend {
			width: initial;

			.ico {
				width: 5rem;
				display: inline-block;
			}
		}

    &:first-child {
	    margin-left: 0;
    }

    &:hover,
    &:active,
    &:focus {
    	background-color: $brand-secondary;

    	.square-link {
    		transition: all $transition-fast;

    		.ico {
	    		color: $actions-li-color-hover;
    		}

	    	.cart-price {
	    		color: #fff;
	    	}
    	}


    }

    .square-link {
    	display: block;
	    min-width: $actions-li-width;
	    height: $actions-li-height;
	    line-height: $actions-li-height;
    }

    .badge {
	    position: absolute;
	    top: -1rem;
	    background: $brand-secondary;
	    height: 2.5rem;
	    width: 2.5rem;
	    line-height: 1.7;
	    color: #fff;
	    border-radius: 100%;
	    right: -1rem;
	    font-size: $font-size-xs;
	    text-align: center;
	    text-shadow: 1px 1px 1px #000;
	    font-weight: 600;
    }

    .wrap-cart-price {
    	display: inline-block;

    	.cart-price {
	    	white-space: nowrap;
	    	font-size: 1.2rem;
	    	font-weight: 600;
	    	color: $body-color;
	    	line-height: initial;
    	}
    }

    .ico {
    	color: $actions-li-color;
    }
	}
}

// Dropdown
.wrap-dd {
	> ul {
		list-style: none;
		padding: 0;
		margin: 0;
		display: block;

		> li {
			&:last-child {
				.media {
					border-bottom: 1px solid $border-color;
					margin: 0;
				}
			}

			.media {
				flex-direction: row;
				padding: 1rem;
				border-bottom: 1px dashed $border-color;

				a {
					display: block;
					padding: 0;
					margin: 0;
				}

				.media-left {
					margin-right: 2rem;
					margin-bottom: 0;
				}

				.media-body {
					position: relative;
					width: 27rem;

					.media-headline {
						font-size: $font-size-sm;
						padding-right: 2rem;
						line-height: initial;
					}

					ul {
						li {
							margin-right: 0;
						}
					}

					.delete {
						position: absolute;
						top: 0;
						right: 0;
					}
				}
			}
		}
	}

  .dd-footer {
  	padding: 1.5rem;
  	background-color: $gray-lighter;

  	.btn {
  		.fa {
  			color: #fff;
  		}
  	}

  	.text-muted {
  		margin-top: 1.5rem;
  	}
  }
}

// =============================================
// TOPBAR
// =============================================

.wrap-topbar {
	height: $topbar-height;
	border-bottom: 1px solid #dfdfdf;
	background: #f9f9f9;

	ul {
		margin: 0;
		padding: 0;
		list-style: none;

		&.navbar-topbar {
			border-right: 1px solid #dfdfdf;
			margin-right: 2rem;
			padding-right: 2rem;
		}

		li {
			padding: 0 1.75rem;
			font-size: 1.2rem;
			color: #aaa;
			display: table-cell;
			white-space: nowrap;
			line-height: $topbar-height;
			font-weight: 600;

			&:last-child {
				padding-right: 0;
			}

			&:first-child {
				padding-left: 0;
			}

	  	.fa-lg {
	  		vertical-align: initial;
	  	}

			a {
				color: #aaa;

				&:hover,
				&:focus {
					color: $link-hover-color;
				}

				&:active {
					color: $link-active-color;
				}
			}
		}
	}
}

// =============================================
// SEARCH
// =============================================

.search-list {
	list-style: none;

	li {
		position: relative;
		padding-bottom: 2rem;
		border-bottom: 1px solid $border-color;
		margin-bottom: 2rem;

		&:before {
			content: "\e902";;
			font-family: 'shopauro' !important;
			position: absolute;
			left: -2rem;
		}

		&:last-child {
			border-bottom: 0;
		}

	}
}

//** Active

.sidr-inner {
	.wrap-search {
		display: table;

		.form-search {
	    display: table-cell;
	    vertical-align: top;
	    width: 100%;

	    .wrap-elements {
	    	 width: 100%;

		    .form-control {
					height: 5rem;
					border: none;
					padding: 0 0 0 5rem;
					font-size: 1.4rem;
		    }

		    .btn {
					position: absolute;
					left: 0;
					border-radius: 0;
					width: 5rem;
					height: 5rem;
					background: none;
					border: none;
					color: #999;
		    }
	    }
		}

		.menu-close {
			position: relative;
	    display: table;
  		background: $brand-secondary;
  		border-color: $brand-secondary;

  		.ico {
  			display: block;
		    height: 5rem;
		    width: 5rem;
		    line-height: 5rem;
	  		color: #fff;
	  		text-align: center;
  		}
		}
	}

  // Contact

  .topbar-contact {
		list-style: none;
		margin: 2rem 0;
    padding: 1rem 0;
    border-top: 1px solid #333;

		li {
			padding: .5rem 1.5rem;
			display: block;
			font-size: 1.6rem;
			color: #666;
			display: inline-block;

			a {
				font-size: 1.6rem;
				color: #aaa;
				display: inline-block;
			}
		}
  }

}

// =============================================
// BREADCRUMB
// =============================================

.wrap-breadcrumb {
	margin: 2rem 0;

	.breadcrumb {
		background:none;
		margin: 0;
		padding: 2rem 0 0 0;
		font-size: 0;

		> li {
			display: inline-block;
			font-size: 1.4rem;
			color: #bbb;

			&:first-child:before {
				content: none;
			}

			&:before {
				font-family: 'shopauro';
				content: '\edbd';
				color: $gray-light;
				font-size: 1rem;
				padding: 0 .5rem;
			}

			a {
				color: #888;
				border: none;

				&:hover,
				&:focus {
					color: $link-hover-color;
				}
			}
		}
	}
}

// =============================================
// CONTENT MENU
// =============================================

.wrap-sub-nav {
	margin-bottom: 3rem;
}

.navbar-content {
	border-radius: $border-radius;

	.tinynav {
		width: 100%;
	}

	// Navbar default

	.nav-titel {
		font-weight: 600;
  	padding: 2rem;
		background: #f0f0f0;
		color: inherit;
		border-bottom: 1px solid $border-color;
	}

	ul {
		list-style: none;
		margin: 0;
		padding: 0;

		li,
		a {
			position: relative;
			color: #777;
			font-weight: 600;
			transition: all $transition-fast;
		}

		li {
			display: block;

			&.li-ico {
				position: relative;

				a {
					padding-left: 2.2rem;

					.ico {
						position: absolute;
						left: 0;
				    line-height: inherit;
					}
				}
			}

			a {
				display: block;
				padding: 1rem 1rem;

				&:hover,
				&:focus {
		  		color: inherit;
				}
			}
		}

		.ico {
			color: $brand-primary;
			line-height: inherit;
	    margin-right: 1.5rem;
	    min-width: 2.5rem;
	    text-align: center;
			transition: all $transition-fast;
		}
	}

  // level #1
  .lvl-1 {
  	padding: 3rem 2rem;

  	> li {
			font-size: 1.6rem;

			> a {
				&:hover,
				&:active {
					//padding: 1rem 3rem;
					background: #eee;
					border-radius: 5px;
					color: inherit;
				}

				&:active {
					color: #fff;
					background: rgba(0,0,0,.3);

					.ico {
						color: #fff;
					}
				}
			}
  	}
  }

  // level #2
  .lvl-2 {
  	margin:0 0 0 1.5rem;

  	> li {
  		a {
				padding: .5rem;
	  		font-size:1.4rem;

	  		.ico {
	  			margin-right: 0;
	  		}
  		}
  	}
  }

  // Navbar colored

  &.bg-color {
  	background: #f9f9f9;
  	border: 1px solid $border-color;

  	ul li a,
  	ul li .ico  {
  		color: $body-color;
  	}

	  // level #1
	  .lvl-1 {
	  	> li {
				> a {
					&:hover,
					&:hover .ico {
						color: $brand-primary;
					}

					&:active {
						color: #fff;
						background: rgba(0,0,0,.8);

						.ico {
							color: $brand-primary;
						}
					}
				}
	  	}
	  }

	  // level #2
	  .lvl-2 {
	  	> li {
	  		a,
	  		a .ico {
	  			color: rgba(0,0,0,.5);
	  		}

	  		a {
	  			&:hover,
	  			&:hover .ico {
	  				color: $body-color;
	  			}
	  		}
	  	}
	  }
  }

}

// =============================================
// SUB NAV
// =============================================

.navbar-content .lvl-1 {
	display: none;
}

// =============================================
// TEXT BOX (Product overview)
// =============================================

.wrap-text-box {
	border: 1px solid $border-color;
	margin-top: 3rem;

	.hgroup {
		padding: 3rem 3rem 0 3rem;
	}

	.text-box {
		.text-box-body {
			padding: 3rem;
		}

		.text-box-footer {
			padding: 3rem;
			border-top: 1px solid #f2f2f2;

			.tipp-box {
				position: relative;
				font-size: 1.6rem;
				color: #888;
				font-style: italic;
				padding-left: 7rem;

				.ico {
					position: absolute;
					left: 0;
					font-size: 4rem;
			    line-height: 4rem;
			    top: 50%;
			    margin-top: -2rem;
  			}
			}
		}
	}
}

// =============================================
// SIDR (Mobile Menu)
// =============================================

.sidr {
  display: block;
  position: fixed;
  top: 0;
  height: 100%;
  z-index: 9999;
  width: $sidebar-width;
  background-color: $sidr-background;
  overflow-x: hidden;
  overflow-y: auto;

  &.right {
    left: auto;
    right: $sidebar-width-neg;
  }

  &.left {
    left: $sidebar-width-neg;
    right: auto;
  }

  .sidr-inner {
    padding: 0;
  }

  .container {
  	padding: 0;
  }

  a {
  	display: block;
  	color: $sidr-link-color;
  }

  // Main nav
  .navbar-main {
  	width: inherit;

  	// Default (all level's)
  	ul {
	  	margin: 0;
	  	padding: 0;
	  	list-style: none;

	  	li {
	  		position: relative;
	  		border-bottom: 1px solid $sidr-border-color-lvl-1;

	  		a {
	  			display: block;
	  			padding: 1rem 1.5rem;

	  			&:hover,
					&:active {
						color: $link-color;
					}
	  		}
	  	}
  	}

		.title {
			display: block;
			text-transform: uppercase;
			font-weight: 700;
			color: #fff;
			padding: 0 1.5rem;
			margin-bottom: 1rem;
			font-size: $font-size-xxs;
		}

		// Transition before
  	.slide-in {
	  	transition: all $sidr-transition ease;
	  	transform: translate(-100%, 0);
	  	opacity: 0;
  	}

  	.fade-in {
	  	transition: all $sidr-transition ease;
	  	transform: translate(0, 10px);
	  	transition-delay: $sidr-transition-delay;
	  	opacity: 0;
  	}

		// Transition after
  	//.open > li,
  	.open > ul > li  {
	  	> .slide-in {
		  	transform: translate(0, 0);
	  		opacity: 1;
	  	}

	  	> .fade-in {
	  		opacity: 1;
	  		transform: translate(0, 0);
	  	}
  	}


	  // level #1
	  .lvl-1 {
	  	li {
	  		a {
	  			&.nav-entries {
	  				position: relative;
	  				z-index: 100;
	  				padding-right: $sidr-button-width;
				    background-color: $sidr-background-lvl-1;
	  			}

	  			&.nav-dd {
				    position: absolute;
				    z-index: 101;
				    top: 0;
				    right: 0;
				    border-left: 1px solid $sidr-border-color-lvl-1;
				    width: $sidr-button-width;
				    background-color: $sidr-background-lvl-1;
				    text-align: center;
				    padding-left: 1.5rem;

				    &:before {
				    	content: none;
				    	display: none;
				    }
	  			}
	  		}
	  	}
	  }

	  // level #1 active
	  .lvl-1 {
	  	> li {
		  	&.active {
		  		// styles
		  	}
	  	}
	  }

	  // level #2 & #3
	  .lvl-2,
	  .lvl-3 {
	  	overflow: hidden;
	  	max-height: 0; // "height: 0" not work with css transitions
  	 	overflow: hidden;
  	 	visibility: hidden;
	  	transition: all $sidr-transition ease-out;
	  	// If use the "height: auto", the effect not works.
	  	// Is necessary some value for the CSS create a CSS animate,
	  	// and we can use "max-height" with a great value for emulate this effect.
	  	&.open {
	  		max-height: 3000px;
	  		transition: max-height $sidr-transition ease-in;
	  	}

	  	.nav-banner {
	  		display: none;
	  	}

	  	li {
	  		&.title,
	  		&:last-child {
	  			border-bottom: none;
	  		}

	  		a {
					&:before {
						position: absolute;
						left: 1.5rem;
						font-family: 'shopauro';
						content: "\e902";
						display: inline-block;
						font-size: inherit;
					}
	  		}
	  	}
	  }

	  // level #2
	  .lvl-2 {
	  	background: $sidr-background-lvl-2;

	  	li {
	  		border-bottom: 1px solid $sidr-border-color-lvl-2;

	  		a {
	  			padding-left: 3rem;
	  			color: $sidr-link-lvl-2;

					&.nav-entries,
					&.nav-dd {
				    background-color: $sidr-background-lvl-2;
					}

	  			&.nav-dd {
				    border-left: 1px solid $sidr-border-color-lvl-2;
	  			}
	  		}
	  	}
	  }

	  // level #2 active
	  .lvl-2 {
	  	border-top: 1px solid $sidr-border-color-lvl-2;

	  	&.open {
	  		visibility: visible;
	  		border-bottom: 1px solid $sidr-border-color-lvl-2;
	  	}
	  }

	  // level #3
	  .lvl-3 {
	  	background: $sidr-background-lvl-3;

	  	li {
	  		border-bottom: 1px solid $sidr-border-color-lvl-3;

	  		a {
	  			padding-left: 4.5rem;
	  			color: $sidr-link-lvl-3;

	  			&:before {
						left: 3rem;
	  			}

					&.nav-entries,
					&.nav-dd {
				    background-color: $sidr-background-lvl-3;
					}

	  			&.nav-dd {
				    border-left: 1px solid $sidr-border-color-lvl-3;
	  			}
	  		}
	  	}
		}

	  // level #3 active
	  .lvl-3 {
  		border-top: 1px solid $sidr-border-color-lvl-3;

	  	&.open {
	  		visibility: visible;
	 			border-bottom: 1px solid $sidr-border-color-lvl-3;
	  	}
	  }

	  // Mega Menu
	  .mega-menu {
	  	.lvl-2-section {
	  		margin-top: 2.5rem;
	  	}
	  }
  }

  // Topbar Nav

	ul {
  	&.navbar-topbar {
	  	margin: 2rem 0;
	  	padding: 0;

  		li {
				padding: .5rem 1.5rem;
				display: block;

  			a {
  				font-size: $font-size-sm;
  				color: #aaa;
  				display: inline-block;

	  			&:hover,
					&:active {
						color: $link-color;
					}
  			}
  		}
  	}
	}


}

// =============================================
// PRODUCT DETAIL
// =============================================

// Product Info

.prod-overview {
	.prod-label {
		display: table;
		width: 100%;
		font-size: 1.6rem;
		margin: 2.5rem 0;
		padding: 1.5rem 0;
		border-top: 1px solid $border-color;
		border-bottom: 1px solid $border-color;

		.prod-system {
			max-width: 6rem;
			display: table-cell;
			vertical-align: middle;

			a {
				display: block;
			}
		}

		.prod-features {
			display: table-cell;
			vertical-align: middle;
			padding-left: 2rem;

			ul {
				list-style: none;
				margin: 0;
				padding: 0;

				li {

				}
			}
		}

		.prod-certificates  {
			ul {
				list-style: none;
				margin: 0;
				padding: 0;
			}
		}
	}
}

// Product Image

.prod-wrap-img {
  display: table;
  padding: 0 10vw;
  margin-bottom: 3rem;

	.prod-img {
    display: table-cell;
    width: 70vw;
    vertical-align: top;

		a {
			display: block;
		}

		img {
			width: 100%;
		}
	}

	.prod-thumb {
		display: table-cell;
    width: 20vw;
    padding-left: 5vw;
    vertical-align: top;

		.caption {
			display: none;
		}

		ul {
			list-style: none;
			margin: 0;
			padding: 0;

			li {
				margin-bottom: 1.5rem;

				&.active {
					a {
						border-color: $link-hover-color;
					}
				}

				a {
					display: block;
					border: 1px solid $border-color;
				}
			}
		}
	}
}

// Product Shop Box

#productWrap {
	position:relative;

	.loading {
	    position: absolute;
	    background: rgba(255,255,255,0.9);
	    top: 0;
	    bottom: 0;
	    left: 0;
	    right: 0;
	    text-align: center;
	    width: 100%;
	    height: 100%;

	    img {
	    	position: absolute;
	    	top: 50%;
	    }
	}
}

.prod-shop-box {
	position: relative;

	.order-options {
		margin-bottom: 3rem;
	}

	select {
		width: 100%;
	}

	.prod-order {
		text-align: right;

		.prod-price {
			font-size: 4.4rem;
			font-weight: 600;
		}

		.prod-package-info {
			font-size: 1.4rem;
			color: #888;
		}

		.prod-shipping-info {
			font-size: 1.4rem;
			color: #888;
			font-style: italic;

			a {
				color: #888;
				text-decoration: underline;
			}
		}

		.prod-order-btn {
			margin-top: 2rem;
		}
	}
}

// Product Tabs

.wrap-tabs {
	margin-top: 3rem;

	.container {
		padding: 0;
	}

	.nav-tabs {
		border: none;

		.nav-link {
			padding: 1.5rem 2.5rem;

			&.active {
				background: #f1f5fa;
			}
		}
	}

	.wrap-icons {
		margin-bottom: 3rem;

		li {
			a {
				display: block;
				margin-bottom: 1rem;
			}
		}
	}

	.wrap-tab-pane {
		background: #f1f5fa;
		border-top: 1px solid $border-color;
		border-bottom: 1px solid $border-color;

		.tab-pane {
			padding: 3rem 0;
		}
	}
}

// Product Recommendations

.wrap-recommendations {
	padding: 3rem 0;

	&.texture-01-wood {
	    background-image: url(/dist/img/texture-01-wood.jpg);
	    background-size: 512px 512px;
	    background-position: top center;
	    background-repeat: repeat;
	}
}

// Product Item

.wrap-prod-item {
	border-radius: $border-radius;
	background: #ddd;
	border: 1px solid $border-color;
	box-shadow: 0px 1px 2px 0px rgba(0,0,0,0.15);
  transition: all 0.3s linear 0s;
  margin: 0 auto 3rem;

	.item-header {
		text-align: center;
		height: 6rem;

		.item-titel {
			height: 100%;
			display: table;
			width: 100%;
			vertical-align: middle;

			.item-titel-text {
		    display: table-cell;
		    color: $body-color;
		    vertical-align: middle;
		    border-bottom: 1px solid $border-color;
			}

			a {
		    display: table-cell;
		    color: #fff;
		    vertical-align: middle;
		    padding: 0 1.5rem;
			}
		}
	}

	.item-body {
		background: #fff;

		.item-wrap-img {
			padding: 3rem 7rem 1rem;

			.item-img {
				a {
					img {
						width: 100%;
					}
				}
			}
		}

		.item-order {
			text-align: center;
			padding-bottom: 2rem;
	    min-height: 17rem;

			.item-price {
				font-weight: 600;
				font-size: 2.6rem;
			}

			.prod-package-info {
				font-size: 1.4rem;
				color: #888;
			}

			.item-shipping-info {
		    font-style: italic;
		    font-size: 1.2rem;
		    font-weight: 600;
		    color: #888;

		    a {
		    	color: #888;
		    }
		  }

			.item-order-btn {
				margin-top: 2rem;
			}
		}
	}
}


//**	Filter (FAQ)

.wrap-filter {

	.flex-wrapper {
		display: -webkit-flex;
	    display: -ms-flexbox;
	    display: flex;
	}

	.btn {
		border-radius: 0;
	}

	.filter-head {
			margin: -1rem -1rem 0 -1rem;
	    padding: 1rem 2rem;
	    background: $gray-lighter;
	    border-bottom: 1px solid $border-color;

	    .form-group {
	    	margin-bottom: 0;
	    }

	    .block-lg {
	    	width: 100%;
	    }
	}

	.filter-content {
    	margin: 0 -1rem -1rem -1rem;

		.filter-item {
		    padding: .5rem 2rem;
		    border-bottom: 1px solid $border-color;
		}
	}
}

// =============================================
// GOOGLE MAPS
// =============================================

.wrapper-maps {
	position: relative;

	.map-caption {
		.inner {
			padding: 1.5rem 0;
			background: #fff;
		}
	}

	#map {
		border-top: 1px solid rgba(0,0,0,.1);
		margin: 0 (-$grid-gutter-half-width);
		min-height: 200px;
		height: 30vh;
	}
}

// =============================================
// SIDEBAR
// =============================================

.wrapper-sidebar {
	//display: flex;
	//flex-direction: column;
	//height: 100%;

	.inner-sidebar {
		padding: 0 1.5rem;
	}
}

// =============================================
// FOOTER
// =============================================

.wrap-footer {
	margin-top: 3rem;

	ul {
		list-style: none;
		margin: 0;
		padding: 0;
	}

	.footer-social {
		position: relative;
		background: #e7eaef;

		&:before {
			content: ' ';
			position: absolute;
			left: 50%;
			width: 5rem;
			height: 8rem;
			background: url('/dist/img/drop.svg') no-repeat center -1px;
			background-size: cover;
		}

		.wrap-circles {
			padding: 2rem 0;

			ul {
				display: table;

				li {
					display: table-cell;
					padding-right: 1rem;
				}
			}

			.circle {
				a {
					width: 4.4rem;
					height: 4.4rem;
					line-height: 4.4rem;
					background: #fff;
					border: 1px solid #d7d7d7;
					border-radius: 50%;
					text-align: center;
					display: block;
					color: #b4b9c2;
					transition: all 250ms;

					&:hover,
					&:focus {
						color: $link-color;
					}

					&:active {
						color: $link-active-color;
					}
				}
			}
		}
	}

	.footer-links {
		background: #f1f5fa;
		padding: 0 0 3rem 0;
		line-height: normal;
		font-size: 1.4rem;
		color: #8e9196;

		.wrap-footer-col {
			.footer-col-headline {
				margin: 3rem 0 2rem 0;
				color: $body-color;
				font-weight: 700;
				font-size: 1.6rem;
			}

			.footer-col-body {
				ul {
					li {
						a {
							color: #8e9196;
							transition: all 250ms;

							&:hover,
							&:focus {
								color: $link-color;
							}

							&:active {
								color: $link-active-color;
							}
						}
					}
				}

				.pay-logos {
					margin-bottom: 1rem;

					img {
						max-width: 13rem;
					}
				}

				.wrap-footer-newsletter {
					margin-top: 1rem;
				}

			}
		}
	}

	.footer-bottom {
		.footer-bottom-text {
			font-size: 1.4rem;
			color: #bbb;
			text-align: right;
			padding: 1.5rem 0;

			a {
				color: #888888;

				&:hover,
				&:focus {
					color: $link-color;
				}

				&:active {
					color: $link-active-color;
				}
			}
		}
	}

}

// =============================================
// CALLBACK
// =============================================

.wrap-callback {
	position: fixed;
	z-index: 3000;
	bottom:-1px;
	right: inherit;
	margin-top: -17.5rem; // half height
	width:100%;
	height:40.3rem;
	backface-visibility:hidden;
	transition: transform 0.4s ease-out;
	transform: translateY(40.3rem);

	&.open {
		transform: translate(0,0);
	}

	.add-datepicker {
		position: relative;
	}

	.bootstrap-datetimepicker-widget {
		width: 20rem !important;
		font-size: $font-size-xxs;

		.table-condensed>thead>tr>th,
		.table-condensed>tbody>tr>th,
		.table-condensed>tfoot>tr>th,
		.table-condensed>thead>tr>td,
		.table-condensed>tbody>tr>td,
		.table-condensed>tfoot>tr>td {
			padding: 0.4rem,0.3rem,0.4rem,0.3rem;
		}
	}


	.callback-toggle {
		position: absolute;
		z-index: 1111;
		top: -5rem;
		right: 1rem;
		background: $brand-primary;
		color: #fff;
		text-align: center;
		text-transform: uppercase;
		font-weight: 600;
		font-size: 1.4rem;
		border-top-left-radius: 3px;
		border-top-right-radius: 3px;

		a {
			display: block;
			color: #fff;
			text-decoration: none;
			width: 5rem;
			height: 5rem;
			font-size: $font-size-xxs;

			&:active {
				// color: lighten(@orange, 30%)!important;
			}

			.fa {
				display: block;
				margin: 0 auto;
				padding-top: 1.5rem;
			}

			.fa-3x {
				font-size: 2.4rem;
			}
		}

	}

	.callback-body {
		position: absolute;
		background: $gray-lighter;
		border-top: 5px solid $brand-primary;
		margin-left:0;
		width: 100%;
		padding: 1.5rem 4rem 1.5rem 1.5rem;

		.titel {
			font-weight: 600;
			font-size: $font-size-sm;
			margin-bottom: 2rem;

			small {
				display: block;
				font-weight: 400;
				color: $gray;
				font-size: $font-size-xs;
			}
		}

		.text-success{
			line-height: normal;
		}


		.form-group {
			margin-left: 0;
			margin-right: 0;

			.input-group-addon {
				line-height: inherit;
			}
		}
	}
}

// =============================================
// CHECKOUT: SHOPPING CART
// =============================================

.table-shopping-cart {
	th {
		padding: 2rem 0;
		min-width: 12rem;
		border-top: none;
	}

	th,
	td {
	  white-space: nowrap;
	}

	td {
		padding: 1rem 0;

		input {
			text-align: center;
		}
	}
}

// =============================================
// HOMEPAGE
// =============================================

//**	Heroteaser

.wrap-heroteaser {
	position: relative;
	margin: 3rem 0;

	.swiper-button-prev,
	.swiper-button-next {
		&:before {
			color: #fff;
		}
	}

	.swiper-button-prev {
		left: 0;
	}

	.swiper-button-next {
		right: 0;
	}

	.swiper-slide {
		display: inline-block;
	}

	.swiper-heroteaser {
		color: #fff;
		margin: 0 (-$grid-gutter-half-width); // container margin

		.caption {
			padding: 2rem;
			position: absolute;
			bottom: 1rem;
			width: 76%;
			left: 0;
			right: 0;
			margin: 0 auto;
			background-color: $overlay-rgb;

			.section {
				font-weight: 600;
				color: $txt-overlay-rgb;
				text-transform: uppercase;
				font-size: 1.2rem;
				margin-bottom: .5rem;
			}

			.hero-headline {
				font-weight: 600;
				font-size: 1.8rem;
				line-height: 1.4;
			}

			.text {
				color: rgba(255,255,255,.85);
				margin-top: 1rem;
				line-height: 1.5;
			}

			.link {
				margin-top: 1rem;
			}
		}
	}
}

//**	Events

.wrap-events {
	margin-bottom: -1px;
	padding-bottom: 2.5rem;

	.event {
		border-bottom: 1px solid $border-color;
    padding-bottom: 2rem;
    margin-bottom: 2rem;

		.event-meta {
			color: #999;

			ul {
				margin: 0;
				font-size: 1.6rem;

				li {
					margin-right: 1.5rem;

					.ico {
						color: #666;
						vertical-align: text-bottom;
					}
				}
			}
		}

		.event-desc {
			margin-top: 2rem;
		}
	}
}

//**	Teaser Card

.swiper-wrapper {
	.post-module {
		margin-top: 0;
		padding: 1.5rem;

		.post-content {
			padding: 0 2rem;
		}

		.description {
			padding: 3rem 0;
		}
	}
}

.post-module {
	margin-top: 3rem;
	padding: 0;
  position: relative;
  z-index: 1;
  background: #FFFFFF;
  flex: 1;
  display: flex;
  align-items: center;
  //height: 470px;
  transition: all 0.3s linear 0s;

	&:hover {
    .thumbnail {
    	background: #000;
      img {
        //transform: scale(1.1);
        opacity: .6;
      }
    }
  }

	.thumbnail {
    background: #fff;
    //height: 34rem;
    overflow: hidden;
    max-width: 100%;

    img {
   		transition: all 0.3s linear 0s;
    }
  }

	.post-content {
    position: absolute;
    bottom: 0;
    left:0;
    right: 0;
    background: #FFFFFF;
    //width: 100%;
    padding: 3rem;
    transition: all 0.3s cubic-bezier(0.37, 0.75, 0.61, 1.05) 0s;

		.category {
			position: absolute;
	    bottom: 100%;
	    left: 0;
			background: rgba(46,97,158,0.85);
	    padding: .25rem 1rem;
	    color: #FFFFFF;
	    font-size: 1.2rem;
	    font-weight: 600;
	    text-transform: uppercase;
		}

		.title {
			font-weight: 600;
			font-size: 1.6rem;
			line-height: 1.4;

			a {
				color: inherit;
			}
		}

		.sub-title {
			font-weight: 400;
			font-size: 1.4rem;
			line-height: 1.6rem;
	    font-style: italic;
	    color: #999;
	    margin-top: .5rem;
    }

		.description {
	    display: none;
	    font-size: 1.4rem;
	    padding-top: 1rem;

	    .post-link {
	    	display: block;
	    	margin-top: 1rem;
	    }
		}
	}
}

form#Farbe {

	#color {
		color: $white;
		text-shadow: 1px 1px 0 rgba(0,0,0,.85);
	}
	.modal-custom {
		max-width: 90%;

		.modal-body {
			padding: 0;
		}
	}

	.color-grid {
		width: 100%;

		.color-group {
			list-style: none;
			margin: 0;
			padding: 0;
			@include clearfix;

			.item {
				width: 10%;
				height: 10rem;
				float: left;
				position: relative;

				.inner {
			    position: absolute;
			    top: 0;
			    bottom: 0;
			    left: 0;
			    right: 0;
			    margin: 0;

			    .name {
				    font-size: 1.2rem;
				    color: rgba(0,0,0,0.3);
				    font-weight: 600;
						position: absolute;
						top: 1.5rem;
						padding:0 1.5rem;
						left: 0;
						right: 0;
						text-align: left;
						transition: all 500ms;
						//text-shadow: 1px 1px 0 rgba(0,0,0,.15);
			    }

					a {
						color: #fff;
						display: block;
						width: 100%;
						height: 100%;
						text-align: center;

						&.read-more {
							&:hover,
							&:focus {
								.ico {
									opacity: 1;
									transform: scale(1);
								}

								.name {
									color: #fff;
									background: rgba(0,0,0,.1);
									text-shadow: 1px 1px 0 rgba(0,0,0,.25);
								}
							}
						}

						&.read-more {
							display: flex;
							align-items: center;
							justify-content: center;

							.ico {
								transition: all 500ms;
								opacity: 0;
						    width: 6rem;
								display: flex;
								align-items: center;
								justify-content: center;
						    height: 6rem;
								border-radius: 50%;
								color: rgba(0,0,0,.25);
								border: 2px solid rgba(0,0,0,.25);
								transition: all 500ms;
								transform: scale(.8);
							}
						}
					}
				}
			}
		}
	}
}
