// Extra small devices (portrait phones, less than 34em)
// No media query since this is the default in Bootstrap

/*
 *	Small devices (landscape phones, 34em and up)
 *
=========================================================================== */

@media (min-width: 34em) {

}

/*
 *	Medium devices (tablets, 48em and up)
 *
=========================================================================== */

@media (min-width: 48em) {

	// =============================================
	// HEADER
	// =============================================

	// Menu

	.menu-fixed {
	 	margin-top: $header-height + $topbar-height;
	}

	.headbar {
		.headbar-list {
			width: 30rem;
		}
	}

	// =============================================
	// SEARCH
	// =============================================

	.search-outer {
    display: table-cell;
    vertical-align: middle;
    padding: 0 5rem;
    width: 100%;

		.wrap-search {
	    .form-search {
	    	.form-control {
					display: table-cell;
					font-size: 1.4rem;
					width: 100%;
					height: 5rem;
					border-top-left-radius: $border-radius-base;
					border-bottom-left-radius: $border-radius-base;
	    	}

	    	.btn {
					height: 5rem;
					width: 5rem;
					line-height: 5rem;
					padding: 0;
					margin-left: -1px;
					border-top-left-radius: 0;
					border-bottom-left-radius: 0;
	    	}
	    }
		}
	}

	// =============================================
	// HEROTEASER
	// =============================================

	.wrap-heroteaser {
		.swiper-button-prev,
		.swiper-button-next {
			top: 50%;
			margin-top: -1.5rem;
			font-size: 4.8rem;
			background: none;
			transition: all 250ms;

			&:before {
				transition: all 250ms;
				font-family: 'shopauro';
				color: $gray-lighter;
			}

			&:hover {
				&:before {
					color: $link-hover-color;
				}
			}

		}

		.swiper-button-prev {
			left: -5rem;

			&:before {
				content: '\e903';
			}
		}

		.swiper-button-next {
			right: -5rem;

			&:before {
				content: '\e902';
			}
		}

		.swiper-heroteaser {
			margin: inherit;

			.caption {
		    max-width: 45rem;
		    left: inherit;
		    right: 3rem;
		    bottom: 3rem;
		 	}
		}
	}

	// =============================================
	// PRODUCT
	// =============================================

	// INfo
	.prod-overview {
		.prod-label {
			.prod-system {
				max-width: 6rem;
			}
		}
	}

	// grid

	.wrap-prod-item {
		min-height: 43rem;
		max-width: 30rem;

		.item-wrap-img {
			display: table;
			// table needs height, so that min-height works
			min-height: 23rem;
			height: 1rem;

			.item-img {
				display: table-cell;
				vertical-align: middle;
			}
		}
	}

	// Thumbnail
	.prod-wrap-img {
		padding: 0;

		.prod-img {
			display: block;
			width: 100%;
			margin-bottom: 1rem;
		}

		.prod-thumb {
			display: block;
			width: 100%;
			padding: 0;

			ul {
				display: table;
				margin: 0 -1rem;

				li {
					display: table-cell;
					padding: 0 1rem;
				}
			}
		}
	}

	// =============================================
	// ACTIONS
	// =============================================

	.wrap-actions {
		min-width: 25rem;
	}

	// ==============================================
	// FULLSCREEN RESPONSIVE IMAGE
	// =============================================

	.menu-fixed,
	.menu-default {
		.img-fullscreen {
			height: calc(100vh - #{$header-height + $topbar-height});
		}
	}

	// ==============================================
	// FORM
	// =============================================

	.form-inline-group {
		max-width: 60vw;
	}

	// ==============================================
	// IMAGE BANNER
	// =============================================

	.img-banner {
		&.row-reserve {
			.img {
		    padding-left: 5vw;
			}
		}

		.img {
			width: 40%;
	    padding-right: 5vw;
		}
	}

	// =============================================
	// MEDIA OBJECT
	// =============================================

	.media {
		flex-direction: row;
		display: flex;
		align-items: flex-start;

		.media-left {
			margin: 0 3rem 0 0;
			flex: 1;
		}

		.media-right {
			margin: 0 0 0 3rem;
			flex: 1;
		}

		.media-img {
			margin: 0;
		}

		.media-body {
	  	margin-left: 3rem;
			flex:4;
		}

		// Row reverse
		&.row-reverse {
			flex-direction: row-reverse;

			.media-body {
		  	margin-left: 0;
		  	margin-right: 3rem;
			}
		}
	}

	// =============================================
	// GOOGLE MAPS
	// =============================================

	.wrapper-maps {
		border-top: 1px solid rgba(0,0,0,.2);
		border-bottom: 1px solid rgba(0,0,0,.2);

		.map-caption {
			display: block;
			position: absolute;
			left: 50%;
			right: 50%;
			top: 50%;
			bottom: 50%;

			.inner {
				margin-left: -50rem;
				padding: 1.5rem;
			}
		}

		#map {
			border: none;
			min-height: 200px;
			height: 40vh;
		}
	}

	// ==============================================
	// Blockquote
	// =============================================

	.blockquote  {
		.blockquote-body,
		.blockquote-footer {
			text-align: left;
		}
	}

}

/*
 *	Large devices (desktops, 62em and up)
 *
=========================================================================== */

@media (min-width: 62em) {

	// ==============================================
	// Typography
	// =============================================

	// Headlines

	h1,
	.h1 {
		font-size: 5.5rem;
	}

	h6,
	.h6 {

	}

	// =============================================
	// HEADER
	// =============================================

	.wrap-header {
		padding: 4rem 0;
		margin-bottom: 0;

		.header {
			height: inherit;

			.wrap-logo {
				width: initial;

				.logo {
		    	width: 15rem;
				}
			}
		}
	}

	// ==============================================
	// EVENTS SHOP HOMEPAGE
	// =============================================

	.wrap-events {
		.event {
			border-left: 1px solid $border-color;
			border-bottom: none;
	    padding-left: 4rem;
	    padding-bottom: 0;
	    margin-bottom: 0;
		}
	}

	// ==============================================
	// FORM
	// =============================================

	.table-responsive {
		display: table;
	}

	// =============================================
	// SUB NAV
	// =============================================

	.tinynav {
		display: none;
	}

	.navbar-content .lvl-1 {
		display: block;
	}

	// =============================================
	// BREADCRUMB
	// =============================================

	.wrap-breadcrumb {

		margin-bottom: 3rem;
	}

	// =============================================
	// MAIN NAV (Desktop Menu)
	// =============================================

	.nav-main {
		border-top: 5px solid $border-color;
		margin-top: -1px;
		border-bottom: 1px solid $border-color;

		.navbar-main {
			.lvl-1 {
				text-align: justify;
				font-size: 0;

				> li {
					margin-top: -5px;
			    width: 16.66666666666667%;

			    &:hover {
			    	a {
				    	.link-txt {
				    		color: #fff;
				    	}
			    	}
			    }

					&.orange {
			    	.link-txt,
			    	a:hover {
			    		color: $orange;
			    	}

						> a:before,
						&:hover > a {
							background-color: $orange;
						}
					}

					&.green {
			    	.link-txt,
			    	a:hover {
			    		color: $green;
			    	}

						> a:before,
						&:hover > a {
							background-color: $green;
						}
					}

					&.blue {
			    	.link-txt,
			    	a:hover {
			    		color: $blue;
			    	}

						> a:before,
						&:hover > a {
							background-color: $blue;
						}
					}

					&.red {
			    	.link-txt,
			    	a:hover {
			    		color: $red;
			    	}

						> a:before,
						&:hover > a {
							background-color: $red;
						}
					}

					&.lightblue {
			    	.link-txt,
			    	a:hover {
			    		color: $lightblue;
			    	}

						> a:before,
						&:hover > a {
							background-color: $lightblue;
						}
					}

					&.olive {
			    	.link-txt,
			    	a:hover {
			    		color: $olive;
			    	}

						> a:before,
						&:hover > a {
							background-color: $olive;
						}
					}

					> a {
						border-left: 1px solid $border-color;
						text-align: center;
						display: table;
						line-height: 10rem;
						height: 10rem;
						width: 100%;

						&:before {
					    content: "";
					    position: absolute;
					    height: 5px;
					    left: 0;
					    display: block;
					    right: 0;
							background: $border-color;
						}

						.link-txt {
					    line-height: normal;
					    display: table-cell;
					    vertical-align: middle;
						}
					}

					&:last-child {
						> a {
							border-right: 1px solid $border-color;
						}
					}
				}
			}
		}
	}

	// =============================================
	// MAIN NAV (Desktop Menu)
	// =============================================

	.navbar-main {
		// Default (all level's)
	  ul {
	  	margin: 0 auto;
	  	padding: 0;
	  	list-style: none;
			box-shadow: $box-shadow;

	  	li {
	  		position: relative;
	  		border: none;

  			&:hover > a,
				&:active > a {
  				color: $link-color;
  			}

	  		a {
	  			position: relative;
	  			display: block;
	  			padding: 0 1rem;
	  			color: #444;

					&:hover,
					&:focus {
						color: $link-hover-color;
					}

					&:active {
						color: $link-active-color;
					}
	  		}
	  	}
	  }

		// Transition
  	.slide-in {
	  	transition: all .4s ease;
	  	transform: translate(-10px, 0);
	  	opacity: 0;
  	}

  	.fade-in {
	  	transition: all .4s ease-in-out;
	  	transform: translate(0, 5px);
	  	transition-delay: .2s;
	  	opacity: 0;
  	}

		// Transition (hover))
		.mega-menu:hover li > .slide-in,
  	.lvl-1 li:hover .lvl-2 > ul > li > .slide-in,
  	.lvl-2 li:hover .lvl-3 > ul > li  > .slide-in {
	  		transition-delay: .1s; // hover intent
		  	transform: translate(0, 0);
	  		opacity: 1;
	  	}

		.mega-menu:hover .fade-in,
  	.lvl-1 li:hover .lvl-2 > ul > li > .fade-in,
  	.lvl-2 li:hover .lvl-3 > ul > li  > .fade-in {
			transition-delay: .3s; // half fade-in time + hover intent
			opacity: 1;
			transform: translate(0, 0);
  	}

	  // level #1
	  .lvl-1 {
			position: initial;
			//display: flex;
			//justify-content: flex-end;
			//margin-right: 2rem;
			box-shadow: none;

			&.nav-entries {
				transition: all .2s ease;
			}

			> li {
	  		display: inline-block;
				//margin: 0 1rem;
				padding: 0;

				> a {
					font-size: 1.8rem;
					//line-height: $header-lg-height;
					font-weight: 600;
					color: #666;
				}
			}
	  }

	  // level #2 & #3
	  .lvl-2,
	  .lvl-3 {
	  	max-height: 0;
	  	transition: all 0s ease; // hover intent
	  	transition-delay: .1s;  // hover intent
	  	opacity: 0;
	  	visibility: hidden;
	    padding: 0;
			position: absolute;
			z-index: 1000;
			margin: 0;
			background: #fff;
			left: 0;

			li {
				border-bottom: 1px solid #eee;

				a {
					white-space: nowrap;
	  			padding: .5rem 1.5rem .5rem 3rem;
	  			font-size: 1.6rem;

					&:before {
						position: absolute;
						left: 1.5rem;
						font-family: 'shopauro';
						content: "\e902";
						display: inline-block;
						font-size: inherit;
					}
				}
			}

			.title {
				display: block;
				text-transform: uppercase;
				font-weight: 700;
				color: #999;
				padding: 0 1.5rem;
				margin-bottom: 1rem;
				border: none;
				font-size: $font-size-xxs;
			}

			.nav-banner {
				display: block;
		    position: absolute;
		    left: 100%;
		    bottom: 0;
		    height: 100%;
		    color: #fff;
		    top: 0;
		    background: #333;
		    min-width: 10rem;
		    text-indent: inherit;
		    font-size: 1.4rem;
			}
		}

	  // level #2
	  .lvl-2 > ul {
	  	padding-top: 2rem;
			text-align: left;
			box-shadow: 0 26px 40px -20px rgba(0,0,0,0.2);
			border: 1px solid #dfdfdf;
	  }

	  // level #3
	  .lvl-3 {
	  	top: 0;
			left: 100%;
			box-shadow: 0 26px 40px -20px rgba(0,0,0,0.2);
			border: 1px solid #dfdfdf;
	  }

	  // Nav Active

	  // level #2 Active
	  .lvl-1 > li {
	  	&:hover,
	  	&:focus {
	  		.lvl-2 {
	  			max-height: 3000px;
	  			opacity: 1;
	  			visibility: visible;
	  		}
	  	}
	  }

	  // level #3 Active
	  .lvl-2 > ul > li {
	  	&:hover,
	  	&:focus {
	  		.lvl-3 {
	  			max-height: 3000px;
	  			opacity: 1;
	  			visibility: visible;
	  		}
	  	}
	  }

	  // Mega Menu
	  .mega-menu {
	  	position: inherit;


	  	.lvl-2 > ul {
			  max-width: 100%;
			  width: 100%;
			  left: 0;
			  right: 0;
    	}

		  .lvl-2,
		  .lvl-3 {
		  	ul {
	    		box-shadow: none;

	    		li {
	    			a {
							white-space: inherit;
	    			}
	    		}
		  	}
		  }
	  }
	}

	// =============================================
	// PRODUCT
	// =============================================

	// Info
	.prod-overview {
		.prod-label {
			.prod-system {
				max-width: 3rem;
			}
		}
	}

	// Product Recommendations
	.wrap-recommendations {
		padding: 6rem 0;
	}

	.wrap-tabs {
		margin-top: 8rem;
	}

	// =============================================
	// FOOTER
	// =============================================

	.wrap-footer {

		.footer-social {
			.wrap-circles {
				padding: 3rem 0;

				.circle {
					a {
						width: 5rem;
						height: 5rem;
						line-height: 5rem;
					}
				}
			}
		}


		.footer-links {
			font-size: 1.6rem;
			line-height: 1.5;

			.wrap-footer-col {
				.footer-col-headline {
					font-size: 1.8rem;
				}
			}
		}
	}

}

/*
 *	Extra large devices (large desktops, 75em and up)
 *
=========================================================================== */

@media (min-width: 75em) {

	// =============================================
	// HEADER
	// =============================================

	.headbar {
		.headbar-list {
			width: 27rem;
		}
	}

}
