// ==============================================
// UTILITIES
// =============================================

//**	Backgrounds

.bg-gray-bordered {
	padding: 1rem;
	background: $gray-lightest;
	border: 1px solid $border-color;
}

// Text

.txt-shadow-white {
	text-shadow: $txt-shadow-white;
}

.txt-shadow-black {
	text-shadow: $txt-shadow-black;
}

// Animations

.txt-fadein {
	transition: all 750ms;
	opacity: 0;

	&.in {
		opacity: 1;
	}
}

.float-left {
	float: left;
}

.float-right {
	float: right;
}

.shadowbox {
	box-shadow: 0px 1px 2px 0px rgba(0,0,0,0.15);
  transition: all 0.3s linear 0s;

	&:hover {
    box-shadow: 0px 1px 35px 0px rgba(0, 0, 0, 0.3);
	}
}

// ==============================================
// Video
// =============================================

.video-section {
	position: relative;
}

// ==============================================
// MODAL
// =============================================

.modal {
	.modal-header {
		position: relative;

		.close {
			position: absolute;
			right: 2rem;
			top: 50%;
			margin-top: -1.3rem;
		}
	}

	&.postal_code {

		.modal-header {
			background: $gray-lightest;
		}

		.input-group-addon {
			background: $blue;
			color: $white;
			border: 1 px solid $blue
		}
	}

	&.colorlight {
		.modal {
			&-content {
				background: #f1f5fa;
			}

			&-header,
			&-footer {
				background: #e7eaef;
			}
		}
	}
}



// =============================================
// PARALLAX
// =============================================

.parallax-section {
	position: relative;
	z-index: 10;

	.parallax-content {
		position: relative;
		z-index: 11;
	}
}

// ==============================================
// IMAGE
// =============================================

.img-wrapper {
	.inner {
		display: flex;
		align-items: center; // fix img height bug
		overflow: hidden;

		&.halign-left {
			width: auto;
			justify-content: flex-end;
		}

		&.halign-right {
			width: auto;
			justify-content: flex-start;
		}
	}
}

.img-circle {
	border-radius: 50%;
}

// ==============================================
// IMAGE BANNER
// =============================================

.img-banner {
	display: flex;
	align-items: center;

	&.row-reverse {
		flex-direction: row-reverse;

		.img {
	    padding-right: 0;
	    padding-left: 10vw;
	    justify-content: flex-start;
		}
	}

	.img {
		width: 30%;
    overflow: visible;
    padding-right: 10vw;
    display: flex;
    justify-content: flex-end;

    > img {
    	max-height: 60vh;
    }

    .img-fluid {
    	align-self: center;
    }
	}
}

// ==============================================
// Media Object
// =============================================

.media {
	flex-direction: column;
	align-items: center;
	//margin: 0 (-$grid-gutter-half-width); // dropdown issues

	.media-left,
	.media-right {
		margin: 0 0 1.5rem 0;
		padding: 0;
		display: block;
	}

	.media-body {
		flex:4;

		.media-heading {
			margin-bottom: 2rem;

			a {
				color: inherit;

				&:hover,
				&:focus {
					color: $link-hover-color;
					text-decoration: $link-hover-decoration;
				}

				&:active {
					color: $link-active-color;
					text-decoration: $link-active-decoration;
				}
			}
		}
	}

	// Row reverse
	&.row-reverse {
		flex-direction: column;
	}
}

// ==============================================
// FORM
// =============================================

.form-inline-group {
	display: flex;
	justify-content: center;
	margin: 0 auto;

	.btn:not(:first-child):not(:last-child):not(.dropdown-toggle) {
		border-radius: 0;
	}

	.btn:first-child {
	  &:not(:last-child):not(.dropdown-toggle) {
			border-bottom-right-radius: 0;
			border-top-right-radius: 0;
	  }
	}

	.btn:last-child:not(:first-child),
	.dropdown-toggle:not(:first-child) {
		border-bottom-left-radius: 0;
		border-top-left-radius: 0;
	}

	&.center-form {
		margin: 0 auto;
	}
}

// ==============================================
// Typography
// =============================================

// Headlines

h1,h2,h3,h4,h5,h6 {
	margin-bottom: 0;
	font-weight: 600;
	color: $body-color;
}

h1,
.h1 {
	//font-size: 4.4rem;
	font-size: 4rem;
	line-height: 1.2;

}

h2,
.h2 {
	font-size: 3.6rem;
	line-height: 1.3;
}

h3,
.h3 {
	font-size: 2.8rem;
	line-height: 1.3;

}

h4,
.h4 {
	font-size: 1.8rem;
	line-height: 1.3;

	margin-bottom: 2rem;
}

h5,
.h5 {
	font-size: 2rem;
	line-height: 1.3;
	font-weight: 600;
}

h6,
.h6 {
	font-size: 1.8rem;
	line-height: 1.5;
	font-weight: 600;
	color: #555;
	margin-bottom: 1rem;
}

p {
	margin-bottom: 3rem;
}

.hgroup {
	margin: 0 0 3rem 0;

	.headline,
	.lead {
		color: inherit; // get color from parent
	}

	.hgroup-sub {
    font-weight: 600;
    font-style: italic;
    font-size: 1.4rem;
    color: #888;
    margin-top: 1rem;
	}

	&.hgroup-sm {
		margin: 0 0 3rem;
	}

	&.hgroup-xs {
		margin: 0 0 2.5rem;
	}

	&.bg-color {
		padding: 0 0 6rem;

		.headline {
			color: inherit;
		}

		.lead {
			color: rgba(255,255,255,.7);

			strong {
				color: #fff;
			}
		}
	}
}

// Alignment

.text-center {
	text-align: center;
}

// Sizing

.text-size-xs {
	font-size: $font-size-xxs;
	line-height: $line-height-xs;
}

.text-size-sm {
	font-size: $font-size-xs;
	line-height: $line-height-sm;
}

.text-size-md {
	font-size: $font-size-md;
	line-height: $line-height-sm;
}

.text-size-lg {
	font-size: $font-size-lg;
	line-height: $line-height-sm;
}

.text-size-xl {
	font-size: $font-size-xl;
	line-height: $line-height-sm;
}

// Lead

.lead {
	font-size: $font-size-lg;
	margin-top: 2rem;
	font-style: italic;
	color: rgba(0,0,0,.5);

	&.lead-sm {
		font-size: $font-size-md;
		line-height: $line-height-md;
		margin-top: .75rem;
	}

	&.lead-xs {
		font-size: $font-size-sm;
		line-height: $line-height-sm;
		margin-top: .5rem;
	}

	// for textured backgrounds
	&.embossing {
		text-shadow: 0 1px rgba(255,255,255,.5);
	}
}

// HR

hr,
.hr {
	display: inline-block;
	border-top: 1px solid rgba(0,0,0,.1);
	margin-top: 3rem;
	margin-bottom: 3rem;
	width: 100%;

	&.hr-hide {
		border-color: transparent;
	}

	&.hr-zero {
		padding: 0;
		margin: 0;
	}

	&.hr-xs {
		margin-top: .5rem;
		margin-bottom: .5rem;
	}

	&.hr-sm {
		margin-top: 1.5rem;
		margin-bottom: 1.5rem;
	}

	&.hr-lg {
		margin-top: 5rem;
		margin-bottom: 5rem;
	}

	&.hr-xl {
		margin-top: 8rem;
		margin-bottom: 8rem;
	}
}

// ==============================================
// LISTS
// =============================================

ul {
	padding-left: 2.2rem;

	&.list-xs {
		font-size: $font-size-xs;
		line-height: $line-height-xs;
	}

	&.list-sm {
		font-size: $font-size-sm;
		line-height: $line-height-sm;
	}

	&.list-ico {
		list-style: none;
		margin: 0;
		padding: 0;

		li {
			position: relative;
			margin-bottom: 0.5rem;

			&:last-child {
				margin-bottom: 0;
			}

			&.li-ico {
				padding-left: 2.2rem;

				.ico {
					position: absolute;
					left: 0;
			    line-height: inherit;
				}
			}
		}
	}

	&.seperator-dots {
		margin: 0;
		padding: 0;
		list-style: none;

		li {
			position: relative;
			display: inline-block;

			&:last-child {
				&:after {
					content: none;
					padding: 0;
				}
			}

			&:after {
				content: '.';
				padding: 0;
				color: $orange;
				line-height: inherit;
			}

			a {
				color: $body-color;
			}
		}
	}

	&.display-flex {
		li {
			margin: 0 2rem;
		}
	}

	&.list-group {
		text-align: left;

		&.list-sm {
			font-size: $font-size-sm;

			li {
				padding-top: 0.5rem;
				padding-bottom: 0.5rem;
			}
		}

		li {
			background-color: transparent;

			&.ico-li {
				padding-left: 3.4rem;

				.fa {
					position: absolute;
					left: 1.25rem;
					line-height: inherit;
				}
			}

		}
	}
}

// ==============================================
// DROPDOWN
// =============================================

.dropdown-menu {
	margin: 1rem 0 0 -1px;
	padding: 0;
	border-color: $border-color;
	box-shadow: 0 5px 5px -3px rgba(0,0,0,0.2);

	&:after, &:before {
		bottom: 100%;
		left: 50%;
		border: solid transparent;
		content: " ";
		height: 0;
		width: 0;
		position: absolute;
		pointer-events: none;
	}

	&:after {
		border-color: rgba(255, 255, 255, 0);
		border-bottom-color: #fff;
		border-width: 5px;
		margin-left: -5px;
	}

	&:before {
		border-color: rgba(223, 223, 223, 0);
		border-bottom-color: $border-color;
		border-width: 6px;
		margin-left: -6px;
	}

	// Right

	&.dropdown-menu-right {

		&:after {
			right: 1.6rem;
			left: inherit;
			margin: 0;
		}

		&:before {
			right: 1.5rem;
			left: inherit;
			margin: 0;
		}
	}

	// Left

	&.dropdown-menu-left {

		&:after {
			left: 1.6rem;
			margin: 0;
		}

		&:before {
			left: 1.5rem;
			margin: 0;
		}
	}

	a {
		padding: 0 1.75rem;
    font-size: $font-size-sm;
    display: block;
	}
}

// ==============================================
// CARDS
// =============================================

.card {
	min-width: 17rem;

	&.no-border {
		border: none;
	}

	.card-header {
    color: #fff;
    background: $brand-secondary;
    width: auto;
    left: 0;
    padding: .5rem 2rem;
    font-weight: $display4-weight;
    font-size: $font-size-xxs;
    border: none;
    position: absolute;
    z-index: 10;
    display: inline-block;
	}

	.hgroup {
		margin: 0;
	}

	.card-title {
		font-size: $font-size-lg;
		font-weight: 600;
		margin-bottom: 0;

	}

	.card-subtitle {
		margin-top: 0;
		font-weight: 400;
		font-style: italic;
		font-size: $font-size-base;
	}

	.card-img-top {
		margin: 0 auto;
	}

	// Card style

		// Transparent
		&.card-transparent {
			background: rgba(255,255,255,.5);

			.card-title {
				color: rgba(0,0,0,.5);
			}

			.card-text {
				color: rgba(0,0,0,.75);
			}

			.card-subtitle {
				color: rgba(0,0,0,.5);
			}
		}

		// Icon
		&.card-icon {
			border: none;
			transition: all $transition-fast;

			// Hover
			&:hover {
				box-shadow: 0 0 25px rgba(0,0,0,.05);

				.inner  {
					box-shadow: inset 0 0 20px 5px rgba(0,0,0,.25);

					.fa {
						transform: scale(.8);
						opacity: .8;
					}
				}
			}

			.top-icon {
				display: flex;
				justify-content: center;
				align-items: center;
				margin: 1.5rem 0;

					.inner {
						transition: all $transition-fast;

						&.bg-color {
							width: 18rem;
							height: 18rem;
							display: flex;
							border-radius: 100%;
							justify-content: center;
							color: #fff;
							align-items: center;
						}

						.fa {
							transition: all $transition-fast;
						}
					}
			}
		}

	// Card sm

	&.card-sm {
		font-size: 1.6rem;

		.card-title {
			font-size: $font-size-base;
			font-weight: 600;
		}

		.card-subtitle {
			font-size: $font-size-xs;
		}
	}
}

// ==============================================
// LIGHTBOX
// =============================================

// prevents colliding with parallax
.lg-on {
	perspective: inherit!important;
  overflow-y: initial!important;
  overflow-x: initial!important;
}

// ==============================================
// IMAGES
// =============================================

// Hover zoom icon

.img-hover {
	position: relative;
	overflow: hidden;

	* {
		transition: all 0.35s ease-in-out;
	}

	.ico {
		position: absolute;
		z-index: 110;
		top: 50%;
		left: 50%;
		text-align: center;
		border-radius: 50%;
		font-size: 2.8rem;
		width: 6rem;
		height: 6rem;
		line-height: 6rem;
		background: #fff;
		box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
		transform: translate(-50%, -50%) scale(0);
		transition: all 300ms 0ms cubic-bezier(0.6, -0.28, 0.735, 0.045);
	}

	// Hover

	&:hover {
		img {
		  opacity: 0.3;
		  filter: grayscale(100%);
		  transform: scale(1.1);
		}

		.ico {
		  transform: translate(-50%, -50%) scale(1);
		  transition: all 300ms 100ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
		}
	}
}

// ==============================================
// Accordion
// =============================================
//**	Accordion

.panel-group {
	border-top: 1px solid $border-color;

	.panel {
		border-bottom: 1px solid $border-color;

		&:last-child {
			border-bottom: none;
		}

		.panel-heading {
			&.active {
				.panel-title {
					> a {
						background-color: #f9f9f9;
						color: $body-color;
					}
				}
			}

			.panel-title {
				margin-bottom: 0;

				> a {
					background-color: #fff;
					display: block;
					padding: 1.5rem 1rem;
			    font-weight: 400;
			    font-size: 1.8rem;

					&.add-icon-left {
						position: relative;
						padding-left: 4rem;

						.ico {
							text-align: center;
					    position: absolute;
					    width: 5rem;
					    left: 0;
					    top: 50%;
					    margin-top: -1rem;
					    font-size: 2rem;
					    line-height: 2rem;
						}
					}
				}
			}
		}

		.panel-body {
			margin: 2rem $grid-gutter-width;
		}
	}
}


// ==============================================
// Blockquote
// =============================================

.blockquote  {
	font-size: $font-size-md;
	border: none;

	.blockquote-body {
		position: relative;
		font-style: italic;
		text-align: center;
		color: $gray-light;

		/*
		&:before,
		&:after {
			font-family: serif;
		}

		&:before {
			content: "\201C";
		}

		&:after {
			content: "\201D";
		}
		*/

		p {
			display: inline;
		}
	}

	.blockquote-footer {
		text-align: center;
		margin-top: 2rem;
		font-weight: 600;
		color: $brand-primary;

		&:before {
			content: none;
		}

		cite {
	    font-weight: 400;
			color: $body-color;
	    display: block;
	    font-style: normal;
	 	}
	}
}

// ==============================================
// BUTTONS
// =============================================

.btn {
	border-radius: $border-radius;

	&:hover,
	&:focus {
		background: $link-hover-color;
		border-color: $brand-secondary;
		color: #fff;
	}

	&:active {
		background: darken($link-hover-color, 10%);
		border-color: darken($link-hover-color, 10%);
	}

	.txt {
    text-align: left;
    line-height: 1.3;

    &.pad-l {
    	padding-left: 1rem;
    }

    &.pad-r {
    	padding-right: 1rem;
    }

    &.txt-uppercase {
    	text-transform: uppercase;
    }
	}

	small {
		display: block;
		color: rgba(255,255,255,.5);
	}
}

// Button Group

.btn-group {
	display: flex;
}

.btn-group > .btn:not(:first-child):not(:last-child):not(.dropdown-toggle) {
  border-radius: 0;
}

// Set corners individual because sometimes a single button can be in a .btn-group and we need :first-child and :last-child to both match
.btn-group > .btn:first-child {
  &:not(:last-child):not(.dropdown-toggle) {
		border-bottom-right-radius: 0;
		border-top-right-radius: 0;
  }
}
// Need .dropdown-toggle since :last-child doesn't apply given a .dropdown-menu immediately after it
.btn-group > .btn:last-child:not(:first-child),
.btn-group > .dropdown-toggle:not(:first-child) {
	border-bottom-left-radius: 0;
	border-top-left-radius: 0;
}

// Button Size
.btn-xl {
  // line-height: ensure even-numbered height of button next to large input
  //@include button-size($btn-padding-y-xl, $btn-padding-x-xl, $font-size-lg, $line-height-lg, $btn-border-radius-lg);
}

// ==============================================
// VIDEOS
// =============================================

.wrap-video-set {
	padding: 3rem 0;

	.wrap-video-slider {
		position: relative;
		margin-bottom: 3rem;

		&:last-child {
			border-bottom: none;
			margin-bottom: 0;
		}

		h3 {
			margin-bottom: 2rem;
		}

		.youtube-item {
			.yt-titel {
		    display: flex;
		    align-items: center;
		    justify-content: center;
				position: absolute;
				z-index: 100;
				left: 0;
				right: 0;
				bottom: 0;
				top: 0;
				padding: 1.5rem;
				text-align: center;
		    font-size: $font-size-xs;
		    line-height: $line-height-md;
		    background: rgba(0,0,0,.5);
				color: #fff;
				text-shadow: 1px 1px 1px rgba(0,0,0,.8);
				font-weight: 600;
				transition: all $transition-fast;

				.insert-titel {
					transition: all $transition-fast;
				}
			}

			.yt-desc {
				margin-top: 1.5rem;
			}

			.wrap-thumb {
		    position: relative;
		    overflow: hidden;

		    .yt-thumb {
		    	margin: -5rem auto;
		    }
			}
		}
	}
}

.youtube-list {
	.youtube-item {
		.insert-thumb {
			display: block;
			position: relative;
			margin:0 auto;

			.yt-duration {
				position: absolute;
				z-index: 110;
				bottom: 0;
				right: 0;
				background: #000;
				color: #fff;
				font-size: $font-size-xxs;
				padding: .2rem .5rem;
			}

			&:hover {
				.yt-titel {
					opacity: 0;

					.insert-titel {
						transform: scale(.8);
					}
				}
			}
		}
	}
}

// ==============================================
// GALLERY
// =============================================

.wrap-gallery {
	.gallery-item{
		margin-bottom: 1.5rem;

		a {
			display: block;
		}
	}
}

// ==============================================
// FULLSCREEN RESPONSIVE IMAGE
// =============================================

.menu-transparent {
	.img-fullscreen {
		overflow: hidden;
		width: 100vw;
		height: 100vh;
	  object-fit: cover;
	  object-position: 50%;
	  font-family: 'object-fit: cover; object-position: 50%'; // IE Hack
	}
}

.menu-fixed,
.menu-default {
	.img-fullscreen {
		overflow: hidden;
		width: 100vw;
		height: calc(100vh - #{$header-height});
	  object-fit: cover;
	  object-position: 50%;
	  font-family: 'object-fit: cover; object-position: 50%'; // IE Hack
	}
}

// ==============================================
// CAROUSEL
// =============================================

.swiper-outer {
	position: relative;

	// Button default

	.swiper-button-prev,
	.swiper-button-next {
		display: flex;
		align-items: center;
		justify-content: center;
		background: none;
		font-size: 5rem;
		top: 0;
		bottom: 0;
		width: 5rem;
		height: auto;
		margin-top: 0;
		opacity: .7; // 0 fore fadein
		text-align: center;
		transition: all $transition-fast;

		&:before {
			font-family: 'shopauro';
			color: #fff;
		}

		&.swiper-button-disabled {
			display: none;
		}
	}

	.swiper-button-prev {
		left: 3rem;
	}

	.swiper-button-next {
		right: 3rem;
	}

	.swiper-button-prev:before {
		content: '\e903';
	}

	.swiper-button-next:before {
		content: '\e902';
	}

	// Button default hover

	&:hover {
		.swiper-button-prev {
			left: 0;
		}

		.swiper-button-next {
			right: 0;
		}

		.swiper-button-prev,
		.swiper-button-next {
			opacity: .7;

			&:hover {
				opacity: 1;
				background: rgba(0,0,0,.25);
			}
		}
	}

	// Button outside

	.swiper-button-prev.btn-outside,
	.swiper-button-next.btn-outside {
		width: 3.5rem;
		font-size: 6rem;

		&:before {
			color: rgba(0,0,0,.5);
		}

	}

	.swiper-button-prev.btn-outside {
		left: -4.5rem;
	}

	.swiper-button-next.btn-outside {
		right: -4.5rem;
	}

	// Button outside hover

		&:hover {
		.swiper-button-prev.btn-outside {
			left: -3.5rem;
		}

		.swiper-button-next.btn-outside {
			right: -3.5rem;
		}

		.swiper-button-prev.btn-outside,
		.swiper-button-next.btn-outside {
			//opacity: .7;

			&:hover {
				opacity: 1;
				//background: rgba(0,0,0,.25);
			}
		}
	}

	// Basic styles

	.swiper-default {
		position: relative;
		overflow: hidden;

		.swiper-container {
			&.container {
				padding: 0;
			}

			.swiper-slide {
				.wrap-caption {
					position: absolute;
			    bottom: 0;
			    top: 0;
			    width: 100%;

			    .halign-center {
			    	.caption {
			    		text-align: center;
			    	}
			    }

			    .caption {
			    	padding: 1.5rem;
				    text-align: left;
				    max-width: 100%;
				    line-height: $line-height-xl;

			    	.carousel-text {
			    		font-size: $font-size-lg;
			    		line-height: 1.3;
			    		margin-bottom: 3rem;
			    	}

			    	.btn {
			    		text-shadow: none;
			    	}
			    }
				}
			}
		}

		.swiper-pagination-progress {
			height: .5rem;
			width: 100%;
			bottom: 0;
			top: inherit;

			.swiper-pagination-progressbar {
				background: $orange;
			}
		}
	}
}

//**	Plugin: Swiper Slider

.swiper-item-pagination {
  //	bullets
  &.swiper-pagination-bullets {
		margin: 2rem auto;
    bottom: 0;
    left: 0;
    display: table;
    right: 0;
    width: inherit;

		.swiper-pagination-bullet {
		  width: 2rem;
		  height: 2rem;
		  background: #fff;
		  border: 1px solid $border-color;
		  opacity: 0.75;
		  transition: all 250ms;
		  margin: 0 1rem;

		  &:active {
		    opacity: 1;
		    border-color: darken($border-color, 15%);
		  }
		}

		.swiper-pagination-bullet-active {
		    opacity: 1;
		    background: $green;
		    border-color: $green;
		}
	}
}
